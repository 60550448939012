import { useState } from 'react';
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { doc, getFirestore, getDoc } from 'firebase/firestore';
import {
  AspectRatio,
  Typography,
  Button,
  Container,
  Grid,
  Stack,
  Input,
  FormLabel,
  FormControl,
  Alert,
  Box,
  Link,
  LinearProgress
} from '@mui/joy';
import { useUser } from '../context/user';

const LandingPage = () => {
  const { loading } = useUser();
  const [error, setError] = useState(null);
  const [processingAuth, setProcessingAuth] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async event => {
    event.preventDefault();
    setProcessingAuth(true);
    setError(null);

    try {
      const userCredential = await signInWithEmailAndPassword(
        getAuth(),
        email,
        password
      );
      const user = userCredential.user;
      const userDocSnapshot = await getDoc(
        doc(getFirestore(), 'admins', user.uid)
      );

      // If this user is not part of the admin collection, show an error
      if (!userDocSnapshot.exists()) {
        setError('You do not have admin access.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setError(
        `Oops, something went wrong. Please try again or contact us for help. (${error})`
      );
    }

    setProcessingAuth(false);
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <Grid container alignItems="center" sx={{ minHeight: '100vh' }}>
        <Grid xs={12} lg={6} display={{ xs: 'none', lg: 'block', padding: 11 }}>
          <AspectRatio
            variant="outlined"
            minHeight={555}
            sx={{ borderRadius: 'sm' }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/elfs-research.appspot.com/o/images%2Felfs-admin.webp?alt=media&token=4c55bb45-cdee-428d-99a1-f5eb43bf7b92"
              alt="ELFS"
            />
          </AspectRatio>
        </Grid>
        <Grid xs={12} lg={6} p={3}>
          <Stack gap={3}>
            <Box>
              <Typography level="h1" gutterBottom>
                ELFS Admin
              </Typography>
              <Typography level="body-lg">
                Welcome to your management portal.
              </Typography>
            </Box>
            {error && <Alert severity="danger">{error}</Alert>}
            <Stack
              component="form"
              spacing={2}
              noValidate
              autoComplete="off"
              onSubmit={handleLogin}
            >
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input
                  required
                  type="email"
                  autoComplete="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  required
                  type="password"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                />
              </FormControl>
              <Button
                type="submit"
                size="lg"
                loading={processingAuth}
                disabled={!email || !password}
              >
                Continue to Your Dashboard
              </Button>
            </Stack>
            <Box textAlign="right">
              <Typography level="body-sm">
                Need assistance or have questions?
              </Typography>
              <Link href="mailto:support@golightlyplus.com" level="body-sm">
                Contact Support
              </Link>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LandingPage;
