import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const SortIcon = ({ field, sortConfig }) => {
  return sortConfig.key === field ? (
    sortConfig.direction === 'ascending' ? (
      <ArrowDownward />
    ) : (
      <ArrowUpward />
    )
  ) : null;
};

export default SortIcon;
