import { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Modal,
  ModalDialog,
  DialogTitle,
  DialogContent,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Option,
  Snackbar,
  FormHelperText,
  LinearProgress,
  Table,
  Link,
  IconButton
} from '@mui/joy';
import {
  collection,
  onSnapshot,
  getFirestore,
  addDoc
} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { PersonAdd, Close, ReadMore } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { format } from 'date-fns';
import SortIcon from './SortIcon';

const checkEmailExists = async email => {
  const functions = getFunctions();
  const checkEmailExistsFunction = httpsCallable(functions, 'checkEmailExists');
  try {
    const result = await checkEmailExistsFunction({ email });
    return result.data.exists;
  } catch (error) {
    console.error('Error checking email:', error);
    return false;
  }
};

const validationSchema = yup.object({
  redcapId: yup.string().required('Study ID is required'),
  studyName: yup.string().required('Study name is required'),
  firstName: yup.string().required("Participant's first name is required"),
  lastName: yup.string().required("Participant's last name is required"),
  dob: yup.date().required('Date of birth is required').nullable(),
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required')
    .test('checkEmailExists', 'Email already in use', async value => {
      if (value) {
        return !(await checkEmailExists(value));
      }
      return true;
    }),
  phoneNumber: yup.string().required('Phone number is required'),
  language: yup.string().required('Language is required')
});

const Participants = () => {
  const [newParticipant, setNewParticipant] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false });
  const [participants, setParticipants] = useState();
  const [filterText, setFilterText] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: 'submissionDate',
    direction: 'descending'
  });
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      redcapId: '',
      studyName: 'ELFS',
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      language: 'english',
      phoneNumber: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setNewParticipant(false);
      try {
        await addDoc(collection(getFirestore(), 'participants'), values);
        console.log('Document successfully written!');
        setSnackbar({
          open: true,
          message: `Successfully added ${values.firstName} ${values.lastName}`,
          color: 'success'
        });
        resetForm(); // Reset the form values to the initial values
      } catch (error) {
        console.error('Error adding document: ', error);
      }
    }
  });

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(getFirestore(), 'participants'),
      querySnapshot => {
        const appsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setParticipants(appsData);
      },
      error => {
        console.error('Error fetching participants:', error);
      }
    );

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const sortedAndFilteredParticipants = () => {
    const filtered = participants.filter(
      participant =>
        participant.firstName
          ?.toLowerCase()
          .includes(filterText.toLowerCase()) ||
        participant.lastName
          ?.toLowerCase()
          .includes(filterText.toLowerCase()) ||
        participant.email.toLowerCase().includes(filterText.toLowerCase()) ||
        participant.redcapId.includes(filterText.toLowerCase())
    );

    return filtered.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const tableHeaders = [
    { key: 'redcapId', label: 'Study ID' },
    { key: 'firstName', label: 'First name' },
    { key: 'lastName', label: 'Last name' },
    { key: 'email', label: 'Email' },
    { key: 'phoneNumber', label: 'Mobile' },
    { key: 'dob', label: 'Date of birth' },
    { key: 'status', label: 'Status' }
  ];

  if (!participants) {
    return <LinearProgress />;
  }

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" my={3} gap={1}>
        <Input
          placeholder="Filter participants..."
          value={filterText}
          onChange={e => setFilterText(e.target.value)}
          endDecorator={
            <IconButton onClick={() => setFilterText('')}>
              <Close />
            </IconButton>
          }
        />
        <Button
          startDecorator={<PersonAdd />}
          size="sm"
          onClick={() => setNewParticipant(true)}
        >
          Add
        </Button>
      </Stack>
      <Table
        hoverRow
        sx={{
          // '& thead th:nth-child(1)': { width: '4%' },
          '& thead th:nth-of-type(4)': { width: '22%' },
          '& td': {
            wordWrap: 'break-word',
            whiteSpace: 'normal',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
          // '& thead th:nth-child(6)': { width: '15%' },
          // '& tr > *:last-child': {
          //   width: '111px'
          // }
        }}
      >
        <thead>
          <tr>
            {/* <th>
              <Checkbox
                checked={selectedRows.length === applications.length}
                onChange={toggleAllRowsSelection}
                sx={{ verticalAlign: 'sub' }}
              />
            </th> */}
            {tableHeaders.map(header => (
              <th key={header.key} onClick={() => requestSort(header.key)}>
                <Link underline="none">
                  {header.label}{' '}
                  <SortIcon field={header.key} sortConfig={sortConfig} />
                </Link>
              </th>
            ))}
            <th />
          </tr>
        </thead>
        <tbody>
          {sortedAndFilteredParticipants().map(participant => (
            <tr key={participant.id}>
              {/* <td>
                <Checkbox
                  checked={selectedRows.includes(application.id)}
                  onChange={() => toggleRowSelection(application.id)}
                />
              </td> */}
              <td>{participant.redcapId}</td>
              <td>{participant.firstName}</td>
              <td>{participant.lastName}</td>
              <td>{participant.email}</td>
              <td>{participant.phoneNumber}</td>
              <td>{format(participant.dob, 'do MMM yyyy')}</td>
              <td>{participant.status}</td>

              <td>
                <Box display="flex" justifyContent="center">
                  <IconButton
                    size="sm"
                    variant="soft"
                    color="neutral"
                    onClick={() => navigate(participant.id)}
                  >
                    <ReadMore />
                  </IconButton>
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal open={newParticipant} onClose={() => setNewParticipant(false)}>
        <ModalDialog
          size="lg"
          sx={{
            overflow: 'scroll'
          }}
        >
          <DialogTitle>Add a participant</DialogTitle>
          <DialogContent
            sx={{
              minHeight: 88
            }}
          >
            Adding a new participant will automatically send them a welcome
            email with instructions to set their own password. Upon their first
            login, they will be prompted to complete their initial survey.
          </DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Stack gap={2}>
              <FormControl
                error={
                  formik.touched.redcapId && Boolean(formik.errors.redcapId)
                }
              >
                <FormLabel>Study ID</FormLabel>
                <Input
                  required
                  id="redcapId"
                  name="redcapId"
                  value={formik.values.redcapId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Study ID..."
                />
                {formik.touched.redcapId && formik.errors.redcapId && (
                  <FormHelperText>{formik.errors.redcapId}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={
                  formik.touched.language && Boolean(formik.errors.language)
                }
              >
                <FormLabel>Study name</FormLabel>
                <Select
                  required
                  id="studyName"
                  name="studyName"
                  value={formik.values.studyName}
                  onChange={(e, newValue) => {
                    formik.setFieldValue('studyName', newValue);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <Option value="ELFS">ELFS</Option>
                  <Option value="MISs-ENDO">MISs-ENDO</Option>
                </Select>
                {formik.touched.studyName && formik.errors.studyName && (
                  <FormHelperText>{formik.errors.studyName}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
              >
                <FormLabel>First Name</FormLabel>
                <Input
                  required
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="First name..."
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <FormHelperText>{formik.errors.firstName}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              >
                <FormLabel>Last Name</FormLabel>
                <Input
                  required
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Last name..."
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <FormHelperText>{formik.errors.lastName}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={formik.touched.dob && Boolean(formik.errors.dob)}
              >
                <FormLabel>Date of Birth</FormLabel>
                <Input
                  required
                  type="date"
                  id="dob"
                  name="dob"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.dob && formik.errors.dob && (
                  <FormHelperText>{formik.errors.dob}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={formik.touched.email && Boolean(formik.errors.email)}
              >
                <FormLabel>Email address</FormLabel>
                <Input
                  required
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Email address..."
                />
                {formik.touched.email && formik.errors.email && (
                  <FormHelperText>{formik.errors.email}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
              >
                <FormLabel>Phone number</FormLabel>
                <Input
                  required
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Phone number..."
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <FormHelperText>{formik.errors.phoneNumber}</FormHelperText>
                )}
              </FormControl>
              <FormControl
                error={
                  formik.touched.language && Boolean(formik.errors.language)
                }
              >
                <FormLabel>Language</FormLabel>
                <Select
                  required
                  id="language"
                  name="language"
                  value={formik.values.language}
                  onChange={(e, newValue) => {
                    formik.setFieldValue('language', newValue);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <Option value="english">English</Option>
                  <Option value="hebrew">Hebrew</Option>
                  <Option value="vietnamese">Vietnamese</Option>
                </Select>
                {formik.touched.language && formik.errors.language && (
                  <FormHelperText>{formik.errors.language}</FormHelperText>
                )}
              </FormControl>
              <Button
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Add Participant
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>
      <Snackbar
        open={snackbar.open}
        color={snackbar.color || 'neutral'}
        autoHideDuration={5555}
        onClose={() => {
          setSnackbar({ open: false });
        }}
      >
        {snackbar.message}
      </Snackbar>
    </Box>
  );
};

export default Participants;
