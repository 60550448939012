import {
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  List,
  ListItemDecorator,
  ListItemButton,
  ListItem,
  Alert,
  Drawer,
  IconButton
} from '@mui/joy';
import {
  Settings,
  Analytics,
  Menu,
  Groups,
  Summarize,
  Person,
  Email
} from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useUser } from '../context/user';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [showDrawer, setShowDrawer] = useState(false);

  const goToPage = page => {
    navigate(page);
    setShowDrawer(false);
  };

  return (
    <>
      <Stack
        sx={{ position: 'fixed', top: 0, width: '100%', p: 1 }}
        direction="row"
        justifyContent={'space-between'}
      >
        <IconButton size="lg" onClick={() => setShowDrawer(true)}>
          <Menu />
        </IconButton>
      </Stack>
      <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Typography level="h3" sx={{ mb: 1 }}>
            ELFS Admin
          </Typography>
          <Typography level="body-sm" startDecorator={<Person fontSize="sm" />}>
            {user?.name}
          </Typography>
          <Typography level="body-sm" startDecorator={<Email fontSize="sm" />}>
            {user?.email}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <List sx={{ flexGrow: 1 }}>
            <ListItem>
              <ListItemButton onClick={() => goToPage('overview')}>
                <ListItemDecorator>
                  <Summarize />
                </ListItemDecorator>
                Overview
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('participants')}>
                <ListItemDecorator>
                  <Groups />
                </ListItemDecorator>
                Participants
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('reports')}>
                <ListItemDecorator>
                  <Analytics />
                </ListItemDecorator>
                Reports
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('settings')}>
                <ListItemDecorator>
                  <Settings />
                </ListItemDecorator>
                Settings
              </ListItemButton>
            </ListItem>
          </List>
          <Alert
            variant="soft"
            endDecorator={
              <Button
                size="sm"
                href="https://contact.golightlyplus.com"
                component="a"
                variant="plain"
                target="_blank"
                color="info"
              >
                Contact
              </Button>
            }
          >
            <Stack>
              <Typography level="body-sm">
                Endometriosis Longitudinal Fertility Study
              </Typography>
              <Typography level="body-xs">
                (version: {process.env.REACT_APP_VERSION})
              </Typography>
              <Typography level="body-xs">
                © Golightly+ {new Date().getFullYear()}
              </Typography>
            </Stack>
          </Alert>
        </Box>
      </Drawer>
      <Box mt={8} mx={3}>
        <Outlet />
      </Box>
    </>
  );
};

export default Dashboard;
