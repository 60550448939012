import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Chip,
  List,
  ListItem,
  Stack,
  Box
} from '@mui/joy';
import {
  Timer,
  Notifications,
  Email,
  NotificationsActive,
  Warning
} from '@mui/icons-material';
import { format, differenceInDays, addDays } from 'date-fns';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  getFirestore
} from 'firebase/firestore';

const NotificationStats = ({ studyStatus, participantId }) => {
  const [notificationHistory, setNotificationHistory] = useState([]);

  useEffect(() => {
    if (!participantId) return;

    const historyRef = collection(
      getFirestore(),
      `participants/${participantId}/notificationHistory`
    );
    const historyQuery = query(historyRef, orderBy('timestamp', 'desc'));

    const unsubscribe = onSnapshot(
      historyQuery,
      snapshot => {
        const history = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNotificationHistory(history);
      },
      err => {
        console.error('Error fetching notification history:', err);
      }
    );

    return () => unsubscribe();
  }, [participantId]);

  if (!studyStatus) return null;

  const { nextNotificationDate, usualCycleLength, lastPeriodDate } =
    studyStatus;

  const formatDate = timestamp => {
    if (!timestamp) return 'Not set';
    return format(
      timestamp instanceof Date ? timestamp : timestamp.toDate(),
      'do MMMM yyyy'
    );
  };

  const getDaysDifference = () => {
    if (!nextNotificationDate) return null;
    return differenceInDays(new Date(), nextNotificationDate.toDate());
  };

  const getDifferenceText = days => {
    if (days === 0) return 'Due today';
    if (days > 0) return `${days} days overdue`;
    return `Due in ${Math.abs(days)} days`;
  };

  const daysDifference = getDaysDifference();

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography level="title-md" startDecorator={<Timer />} gutterBottom>
          Survey Schedule
        </Typography>

        <Stack spacing={2} sx={{ mb: 2 }}>
          <Box>
            <Typography level="body-sm">
              Next survey due: {formatDate(nextNotificationDate)}
              {daysDifference !== null && (
                <Chip
                  size="sm"
                  variant="soft"
                  color={daysDifference > 0 ? 'warning' : 'neutral'}
                  sx={{ ml: 1 }}
                >
                  {getDifferenceText(daysDifference)}
                </Chip>
              )}
            </Typography>
          </Box>

          <Box>
            <Typography level="body-sm" sx={{ mb: 1 }}>
              Notification schedule for this survey:
            </Typography>
            <List>
              <ListItem>
                <Stack direction="row" spacing={1}>
                  <NotificationsActive fontSize="small" />
                  <Typography level="body-sm">
                    Initial Survey: {formatDate(nextNotificationDate)}
                  </Typography>
                </Stack>
              </ListItem>
              <ListItem>
                <Stack direction="row" spacing={1}>
                  <NotificationsActive fontSize="small" />
                  <Typography level="body-sm">
                    First Reminder:{' '}
                    {formatDate(addDays(nextNotificationDate.toDate(), 4))}
                  </Typography>
                </Stack>
              </ListItem>
              <ListItem>
                <Stack direction="row" spacing={1}>
                  <NotificationsActive fontSize="small" />
                  <Typography level="body-sm">
                    Final Reminder:{' '}
                    {formatDate(addDays(nextNotificationDate.toDate(), 8))}
                  </Typography>
                </Stack>
              </ListItem>
            </List>
          </Box>

          <Divider />

          <Stack spacing={1}>
            <Typography level="body-sm">
              Usual cycle length:{' '}
              {usualCycleLength ? `${usualCycleLength} days` : 'Not set'}
            </Typography>
            <Typography level="body-sm">
              Last period:{' '}
              {lastPeriodDate ? formatDate(lastPeriodDate) : 'Not recorded'}
            </Typography>
          </Stack>
        </Stack>

        <Divider />

        <Typography
          level="title-md"
          startDecorator={<Notifications />}
          sx={{ mt: 2, mb: 1 }}
        >
          Notification History
        </Typography>

        {notificationHistory.length > 0 ? (
          <List>
            {notificationHistory.map(notification => (
              <ListItem key={notification.id}>
                <Stack spacing={1} width="100%">
                  <Stack direction="row" spacing={1} alignItems="center">
                    {notification.method === 'email' ? (
                      <Email fontSize="small" />
                    ) : (
                      <NotificationsActive fontSize="small" />
                    )}
                    <Typography level="body-sm">
                      {notification.type === 'monthly'
                        ? 'Survey Due'
                        : notification.type === 'firstReminder'
                          ? 'First Reminder'
                          : 'Final Reminder'}
                    </Typography>
                    <Chip
                      size="sm"
                      color={notification.success ? 'success' : 'danger'}
                    >
                      {notification.method}
                    </Chip>
                  </Stack>
                  <Typography level="body-xs" sx={{ ml: 4 }}>
                    Sent: {formatDate(notification.timestamp)}
                  </Typography>
                  {!notification.success && (
                    <Typography level="body-xs" color="danger" sx={{ ml: 4 }}>
                      <Warning
                        fontSize="small"
                        sx={{ mr: 0.5, verticalAlign: 'text-bottom' }}
                      />
                      Failed to send: {notification.error}
                    </Typography>
                  )}
                </Stack>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography level="body-sm" sx={{ color: 'neutral.500' }}>
            No notifications have been sent to this participant yet
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default NotificationStats;
