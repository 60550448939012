import { Container, Grid, Button } from '@mui/joy';
import { getAuth, signOut } from 'firebase/auth';

const Settings = () => {
  return (
    <Container>
      <Grid container alignItems="center">
        <Grid xs={12}>
          <Button onClick={() => signOut(getAuth())}>Sign Out</Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
